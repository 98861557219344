<template>
<div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid pt-5">
        <div class="container h-100">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-12">
                    <div class="kt-portlet">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    Account Activation
                                </h3>
                            </div>
                        </div>
                        <PortletLoader v-if="loading" />
                        <div
                            v-else-if="error"
                            class="kt-portlet__body"
                        >
                            <div
                                class="alert alert-solid-danger alert-bold"
                                role="alert"
                            >
                                <div class="alert-text">
                                    {{ error }}
                                </div>
                            </div>
                        </div>

                        <form
                            v-else
                            class="kt-form mb-3"
                        >
                            <div class="kt-portlet__body">
                                <div class="row">
                                    <div class="col-sm-6 col-lg-6 col-xl-6">
                                        <div class="form-group">
                                            <label>Enter a New Password</label>
                                            <input
                                                v-model="change.password1"
                                                type="password"
                                                class="form-control"
                                                autocomplete="off"
                                                placeholder="Password"
                                                @input="checkPassword"
                                            >
                                        </div>
                                        <div class="form-group">
                                            <label>Enter a New Password Again</label>
                                            <input
                                                v-model="change.password2"
                                                type="password"
                                                class="form-control"
                                                autocomplete="off"
                                                placeholder="Confirm password"
                                                @input="checkPassword"
                                            >
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-lg-6 col-xl-6">
                                        <div
                                            class="alert alert-light"
                                            role="alert"
                                        >
                                            <div class="alert-icon">
                                                <i
                                                    v-if="!valid_password"
                                                    class="flaticon-warning kt-font-warning"
                                                />
                                                <i
                                                    v-else
                                                    class="flaticon2-check-mark kt-font-success"
                                                />
                                            </div>
                                            <div class="alert-text">
                                                Password Requirements:
                                                <ul class="mt-3">
                                                    <li :class="{ is_valid: contains_eight_characters }">
                                                        8 Characters
                                                    </li>
                                                    <li :class="{ is_valid: contains_number }">
                                                        Contains Number
                                                    </li>
                                                    <li :class="{ is_valid: contains_uppercase }">
                                                        Contains Uppercase
                                                    </li>
                                                    <li :class="{ is_valid: contains_special_character }">
                                                        Contains Special Character
                                                    </li>
                                                    <li :class="{ is_valid: passwords_match }">
                                                        Both Passwords Match
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-portlet__foot">
                                <button
                                    v-if="!loading"
                                    type="button"
                                    :disabled="error && valid_password"
                                    class="btn btn-info pull-right"
                                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                                    @click="save()"
                                >
                                    Save Password and Login
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import PortletLoader from '../components/PortletLoader.vue';
import network from '../lib/network';

export default {
    name: 'AccountActivation',
    components: {
        PortletLoader,
    },
    data() {
        return {
            loading: true,
            error: null,
            saving: false,
            passwordResetGuid: null,
            studentGuardianId: null,
            userId: null,
            emailAddress: null,
            change: {
                password1: null,
                password2: null,
            },
            password_length: 0,
            contains_eight_characters: false,
            contains_number: false,
            contains_uppercase: false,
            contains_special_character: false,
            passwords_match: false,
            valid_password: false,
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        checkPassword() {
            this.password_length = this.change.password1.length;
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

            if (this.password_length >= 8) {
                this.contains_eight_characters = true;
            } else {
                this.contains_eight_characters = false;
            }

            this.contains_number = /\d/.test(this.change.password1);
            this.contains_uppercase = /[A-Z]/.test(this.change.password1);
            this.contains_special_character = format.test(this.change.password1);
            this.passwords_match = Boolean(this.change.password1 === this.change.password2);

            if (this.contains_eight_characters === true
                && this.contains_special_character === true
                && this.contains_uppercase === true
                && this.passwords_match === true
                && this.contains_number === true) {
                this.valid_password = true;
            } else {
                this.valid_password = false;
            }
        },
        save() {
            const v = this;
            if (v.saving) return;
            if (!v.valid_password) return;
            v.saving = true;
            const params = {
                passwordResetGuid: v.passwordResetGuid,
                studentGuardianId: v.studentGuardianId,
                userId: v.userId,
                emailAddress: v.emailAddress,
                password: v.change.password1,
            };
            network.auth.savePassword(params, (err) => {
                v.saving = false;
                if (err) {
                    v.showError(err);
                    return;
                }
                network.auth.userLogin(params.emailAddress, params.password, (loginErr, result) => {
                    if (loginErr) {
                        v.showError(loginErr);
                        return;
                    }
                    window.location.href = '/home';
                });
            });
        },
        load() {
            const v = this;
            v.loading = true;
            v.error = null;
            const { query } = v.$route;
            let activationCode = null;
            if (query) {
                activationCode = v.$route.query?.act || null;
            }
            if (!activationCode) {
                v.error = 'Invalid activation code';
                return;
            }

            network.auth.passwordGuidDetails(activationCode, (err, res) => {
                v.loading = false;
                if (err || !res.user) {
                    v.error = 'Invalid registration link';
                    return;
                }
                v.passwordResetGuid = res.user.passwordResetGuid;
                v.emailAddress = res.user.emailAddress;
                v.studentGuardianId = res.user.studentGuardianId || null;
                v.userId = res.user.userId || null;
            });
        },
    },
};

</script>
